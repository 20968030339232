
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");


@import "../../../node_modules/bootstrap/scss/_functions.scss";
@import "theme/variables";

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

body {
    background-color: var(--bs-body-bg);
}

.hero-video {
    position: relative;
    overflow: hidden;
}

.video-background {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    z-index: 0;
}

.hero-content {
    position: relative;
    z-index: 1;
    text-align: center;
    color: #fff;
    padding: 175px 20px; /* Adjust as needed */
}


.social-bar {
    a {
        display: block;
        transition: color 0.3s ease;
        color: rgba(0, 0, 0, 0.5);
        &:hover {
            color: #FFF;
            
        }
    }

}